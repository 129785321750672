import Navbar from "./Navbar"


export default function Header() {
    return (
        <header className="">
            <Navbar />
        </header>
    )
}
